import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import CustomSoundPlayer from "../components/CustomSoundPlayer";
import { ArrowLeft } from "react-feather";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { FiDownload } from "react-icons/fi";
import SubscribeModal from "../components/SubscribeModal.jsx";

export const CollectionTemplate = props => {
  const PostContent = props.contentComponent || props.Content;
  let clientId = "L6pWQKWWm6oT4Nv0mTWEkxKypNpleA5m";

  return (
    <section className="section">
      {props.helmet || ""}
      <SubscribeModal />
      <div className="container blog-post">
        <Link to="/" className="back-link">
          <ArrowLeft size={20} className="mr-1" />
          <span>Back</span>
        </Link>
        <div className="row">
          <div className="col-4 image-col">
            <Img
              fluid={
                props.Image
                  ? props.image.childImageSharp.fluid
                  : props.defaultImage.childImageSharp.fluid
              }
            />
          </div>
          <div className="col-8">
            <h1 className="blog-post-header">{props.title}</h1>
            <CustomSoundPlayer
              resolveUrl={props.soundcloudLink}
              clientId={clientId}
              onReady={() => {}}
            />
            <PostContent content={props.content} />
            {props.dropboxLink ? (
              <a
                href={props.dropboxLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="d-flex align-items-center">
                  <FiDownload size={"20px"} className="mr-1" />
                  <span>Download via Dropbox</span>
                </div>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

CollectionTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
  soundcloud: PropTypes.string,
  dropboxLink: PropTypes.string,
  image: PropTypes.object,
  tags: PropTypes.array
};

const Collection = ({ data }) => {
  const { markdownRemark: post, defaultImage } = data;

  return (
    <Layout>
      <CollectionTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={""}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        defaultImage={defaultImage}
        dropboxLink={post.frontmatter.dropboxLink}
        soundcloudLink={post.frontmatter.soundcloud}
      />
    </Layout>
  );
};

Collection.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default Collection;

export const pageQuery = graphql`
  query PlayListById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        soundcloud
        dropboxLink
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
    defaultImage: file(relativePath: { eq: "sean.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
